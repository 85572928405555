body {
  background-color: #3f4552;
  overflow: hidden;
  touch-action: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rel {
/*  position: relative;*/
  width: 100vw;
  height: 100vh;
}
svg #dot1 circle {
  cursor: pointer;
}

#hoveredCluster {
  position: fixed;
  top: 0px;
  left: 0px;
  font-size: 24pt;
  padding: 18px 20px 25px 20px;
  width: 22%;
/*  height: 26pt;*/
  text-align: left;
  color: #fff;
/*  text-shadow: 1px 3px 6px #000000;*/
  background-color: #3335;
/*  box-shadow: 15px 20px 45px 0 #3335;*/
  text-shadow: 15px 20px 45px 0 #3335;
}

#menu {
  top: 0;
  right: 0;
  position: fixed;
  margin: 20px 20px;
  background-color: rgba(63,69,82, 0.5);
  border-radius: 3px;
  font-size: 14pt;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#subtitle {
  pointer-events: none;
  position: fixed;
  top: 0px;
  left: 0px;

  width: 30%;
/*  font-size: 26pt;*/
  font-size: clamp(18pt, 3.5vw, 30pt);

  font-family: 'Crimson Text', serif;
  padding: 18px 20px 25px 25px;
 
  text-align: left;
  color: #fff;
  text-shadow: 15px 20px 45px 0 #3335;

}

.menuitem {
  color: #fff;
  text-align: left;
  padding: 6px 12px;
  border-radius: 3px;
  cursor: pointer;
  text-shadow: 1px 0px 2px #333;
}
.menuitem .prefix {
  display: inline-block;
  width: 20px;
}

.smaller {
  font-size: 0.8em;
}
.smaller img {
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -2px;
}
.menuitem.smaller:hover {
  background-color: transparent;
}
.menuitem:hover, .menuitem.pinned {
  background-color: #333;
}

#hoveredTweet {
  max-height: 85%;
  margin: 0.5em;
  position: fixed;
  visibility: visible;
  width: clamp(200px, 28%, 360px);
  border-radius: 5px;
  text-align: left;
  overflow-y: scroll;
  line-height: 1.3em;
  font-size: clamp(12px, 1.2vw, 16px);
}
#hoveredTweet .date {
  background-color: #333;
}
#hoveredTweet .date a {
  color: white;
  text-decoration: none;
}
#hoveredTweet .bar {
  height: 5px;
  width: 100%;
}
#hoveredTweet .tweet {
  padding: 10px 1em 2em 20px;
  margin: 0;
  background-color: #fffd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-shadow: 1px 1px 2px #fff;
}
#hoveredTweet br {
  font-size: 0.5em;
}
#hoveredTweet .date {
  margin: 0;
  padding: 10px 1em 6px 20px;
  font-weight: bold;
  font-size: 10pt;
  color: #555;
}

.rel #hoveredTweet {
  visibility: visible;
}

foreignObject {
  overflow: visible;
  margin: 0;
  padding: 0;
  z-index: 1;
  cursor: default;
}

foreignObject > div {
  transform-origin: top left;
  width: 200px;
}
.clusterLevel0 {
  transform: scale(0.6);
}
.clusterLevel1 {
  transform: scale(0.3);
}

.cluster {
  padding: 0 1em;
  margin: 0;
  text-shadow: 1px 1px 3px #000000;
  color: white;
  width: 150px;
  border-radius: 10px;
  font-size: 8pt;
  transform: translate(-50%, -50%);
}
.cluster.hovered {
  opacity: 0.3;
  /*font-size: 16pt;
  transform: translate(-120%, -50%);*/
}

.dimmed {
  opacity: 0.4;
}

.search {
  margin-bottom: 1em;
}

.collections {
  position: fixed;
  bottom: -3px; /* not sure why this is needed, but there is a gap otherwise */
  left: 0;
}
.tabs {
  display: flex;
  flex-direction: row;
}
.tab {
  flex: 0 100px;
  color: #fff;
  cursor: pointer;
  padding: 0px 7px;
  margin-right: 4px;
  background-color: #5557;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.tab:hover, .tab.pinned {
  background-color: #3337;
}

.collections:hover .tab:hover,
.collections:hover .tab.pinned,
.collections:hover svg.collection {
 background-color: #333b;
}

svg.collection {
  height: 70px;
  background-color: #3337;
}
svg.collection circle {
  cursor: pointer;
}
svg.collection circle:hover {
  fill: white;
}
.editable.double.important {
  cursor: pointer !important;
}
.tab .editIcon {
  display: inline-block;
  padding: 5px 10px 5px 5px;
  margin-left: -10px;
  margin-top: 2px;
  opacity: 0.5;
/*  visibility: hidden;*/
}
.tab:hover .editIcon {
  visibility: visible;
}
.tab:hover .editIcon:hover {
  opacity: 1.0;
}
